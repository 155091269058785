import React from 'react';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Breadcrumb from "../components/breadcrumb";
import Sidebar from '../components/sidebar';
import Cta from "../components/cta";

import main_img from "../images/termisk/svetsarbete-main.jpg";
import main_2_img from "../images/termisk/plasma.jpg";
import img_1 from '../images/termisk/termisk_1.jpg';
import img_2 from '../images/termisk/termisk_2.jpg';
import img_3 from '../images/termisk/termisk_3.jpg';
import img_4 from '../images/termisk/termisk_4.jpg';
import favicon from '../images/favicon.ico';

function Svetsarbete() {
    return (
        <Layout>
            <Helmet>
                <title>Certifierad smidesföretag för dina svetsarbete - www.danasplat.se</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Vi utför svetsarbete i Södertälje, stockholm, Södermanland och Uppland. Alla våra svetsare är certifierade och licensierade." />
                <meta name="keywords" content="Svetsarbeten, svets" />
                <meta property="og:title" content="Svetsarbete - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/svetsarbete" />
                <link rel="cannonical" href="https://danasplat.se/svetsarbete" />
            </Helmet>

            <MyNavbar active_page="" />
            <section>
                <div className="h-60 md:h-96 w-screen bg-svetsarbete bg-center bg-no-repeat bg-cover">
                    <div className="h-60 md:h-96 w-screen  bg-opacity-50 bg-black flex justify-center items-center ">
                        <h1 className="text-4xl sm:text-5xl md:text-7xl font-extrabold text-center text-white">Svetsarbete</h1>
                    </div>
                </div>
                <Breadcrumb page="Svetsarbete"/>
            </section>

            <section>
                <div className="container mx-auto px-4 pt-24">
                    <div className="grid lg:grid-cols-2 gap-24 lg:gap-10">
                        <div className="bg-white">
                            <div className="w-full h-full flex flex-col justify-center">
                                <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-left text-green-900">Vi har licensierade och certifierade svetsare</h2>
                                <p className="lg:text-xl text-gray-600 mt-12">Våra Svetsare är välutbildade och har den kompetens, de licenser, prövningar och certifieringar som krävs för att utföra de avancerade svetsuppdragen.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Våra svetsare utbildas fortlöpande och uppdateras gällande metoder, regler och yrkesspecifik kunskap. Det är viktigt för oss att arbetet och tillverkningen skall ske under säkra former och skall inte påverka medarbetare eller arbetsresultatet negativt.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Välkommen att kontakta oss!</p>
                                <Link to="/kontakt" className="block mt-12">
                                    <span className="bg-red-700 shadow-xl text-white text-md md:text-xl py-4 px-6 rounded hover:bg-red-800">Kontakta oss</span>
                                </Link>
                            </div>
                        </div>

                        <div className="w-full h-full">
                            <div className="w-full h-full bg-image-square bg-no-repeat bg-custome bg-right-bottom">
                                <div className="w-full h-full bg-image-circel bg-no-repeat bg-custome bg-left-top p-4 lg:p-8">
                                    <img alt="Svetsarbete" className="w-full rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md  shadow-xl object-cover h-full object-center block" src={main_img} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 py-24">
                    <hr/>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4">
                    <div className="grid lg:grid-cols-2 gap-24 lg:gap-10">
                        <div className="w-full h-full">
                            <div className="w-full h-full bg-image-square bg-no-repeat bg-custome bg-right-bottom">
                                <div className="w-full h-full bg-image-circel bg-no-repeat bg-custome bg-left-top p-4 lg:p-8">
                                    <img alt="Plasma och Svetsarbete" className="w-full rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md  shadow-xl object-cover h-full object-center block" src={main_2_img} />
                                </div>
                            </div>
                        </div>

                        <div className="bg-white">
                            <div className="w-full h-full flex flex-col justify-center">
                                <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-left text-green-900">Plasma bearbetning</h2>
                                <p className="lg:text-xl text-gray-600 mt-12">Vi på Danas Plåt och Smide AB har en modern verkstad och erbjuder även plasma bearbetning.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Tryck på knappen nedan för mer information eller kom i kontakt med oss så berättar vi gärna mer.</p>
                                <Link to="/plasma" className="block mt-12">
                                    <span className="shadow-xl border border-red-700 text-red-700 text-md md:text-xl py-3 px-6 rounded hover:bg-red-800 hover:text-white">Läs mer</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container mx-auto px-4 pt-24 pb-32">
                    <hr/>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pb-32">
                    <div className="grid grid-cols-12 gap-10">
                        <div className="col-span-full lg:col-span-3">
                            <Sidebar />
                        </div>
                        <div className="col-span-full lg:col-span-9">
                            <div className="w-full grid lg:grid-cols-2 gap-10">
                                <img alt="Svetsarbete i Södertälje" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_1} />
                                <img alt="Svetsare utför svetsarbete" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_2} />
                                <img alt="anlita Svetsare" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_3} />
                                <img alt="Svetsarbete nära mig" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_4} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Cta />
            <Footer />
        </Layout>
    );
}
export default Svetsarbete;